import React from "react";
import { SiNamecheap } from "react-icons/si";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import Visibility from "../utils/Visibility";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../store/indexStore";
import { IoPhonePortraitOutline } from "react-icons/io5";
function BranchInfo(props) {
  const { name } = indexStore();

  const { t } = useTranslation();

  return (
    <div className="text-onBackground flex flex-col gap-1 pb-2 border-b-2 border-dotted border-onBackground">
      <p className="text-lg font-medium "> {t("gloabl.branchInfo")}:</p>
      <div className="flex flex-col gap-2 ">
        <p className="flex gap-1 items-center">
          <SiNamecheap /> {props.branch[name]}
        </p>

        <p className="flex gap-1 items-center">
          <CiLocationOn />
          {props.branch[name == "name_en" ? "address_en" : "address_ar"]}
        </p>
        <Visibility visible={props.branch.phone}>
          <p className="flex gap-1 items-center">
            <MdOutlinePhoneInTalk />
            <span dir="ltr"> {props.branch.phone}</span>
          </p>
        </Visibility>
        <Visibility visible={props.branch.mobile}>
          <p className="flex gap-1 items-center">
            <IoPhonePortraitOutline />
            <span dir="ltr"> {props.branch.mobile}</span>
          </p>
        </Visibility>
      </div>
    </div>
  );
}

export default BranchInfo;
