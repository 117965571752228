import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../store/indexStore";
import BottomSheet from "../ButtomSheet/BottomSheet";

const LangSelect = (props) => {
  const { changeLang, lang, changeLangBottomSheet, langOpenBottomSheet } =
    indexStore();
  const { i18n } = useTranslation();
  const [toogleSwitch, setToogleSwitch] = useState(lang === "ar");
  const { t } = useTranslation();

  useEffect(() => {
    const handleLanguageChange = () => {
      i18n.changeLanguage(lang);
      document.documentElement.dir = i18n.dir();
      setToogleSwitch(lang === "ar");
    };

    if (lang) {
      handleLanguageChange();
    } else {
      changeLang(localStorage.getItem("Lang") || "en");
    }
  }, [lang]);

  const onChangeToogle = (e) => {
    setToogleSwitch(!toogleSwitch);
    changeLang(e ? "ar" : "en");
    changeLangBottomSheet(false);
  };
  return (
    <BottomSheet
      toggleOpen={(value) => changeLangBottomSheet(value)}
      title={t("gloabl.changeLang")}
      open={langOpenBottomSheet}
    >
      <div className="flex flex-col justify-around gap-5 text-onBackground bg-background">
        <p
          onClick={() => onChangeToogle(true)}
          className={`text-lg font-semibold p-2 cursor-pointer ${
            lang == "ar" ? "bg-primary text-onPrimary" : ""
          }`}
        >
          العربية
        </p>
        <p
          onClick={() => onChangeToogle(false)}
          className={`text-lg font-semibold p-2 cursor-pointer ${
            lang == "en" ? "bg-primary text-onPrimary" : ""
          }`}
        >
          English
        </p>
      </div>
    </BottomSheet>
    // </div>
  );
};

export default LangSelect;
