import React from "react";
import { Route, Routes } from "react-router-dom";
import RestaurantRoutes from "./components/RestaurantRoutes/RestaurantRoutes";
import ContactUs from "./components/MainPageWebsite/ContactUs/ContactUs";
import HomePage from "./components/MainPageWebsite/HomePage/HomePage";
import { indexStore } from "./store/indexStore";
import PrivacyPolicy from "./components/MainPageWebsite/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./components/MainPageWebsite/TermsOfService/TermsOfService";
import LangSelect from "./components/langSelect/langSelect";

function App() {
  const { style } = indexStore();
  return (
    <div
      style={{
        "--primary-color": style && style.primary ? style.primary : "#000",
        "--onPrimary-color":
          style && style.onPrimary ? style.onPrimary : "#fff",
        "--secondary-color":
          style && style.secondary ? style.secondary : "#000",
        "--onSecondary-color":
          style && style.onSecondary ? style.onSecondary : "#fff",
        "--enable-color": style && style.enable ? style.enable : "#fff",
        "--disable-color": style && style.disable ? style.disable : "#696969",
        "--background-color":
          style && style.background ? style.background : "#fff",
        "--onBackground-color":
          style && style.onBackground ? style.onBackground : "#333",
      }}
    >
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="/contact" Component={ContactUs} />
        <Route path="/privacy-policy" Component={PrivacyPolicy} />
        <Route path="/terms-of-service" Component={TermsOfService} />
        <Route path="/*" Component={RestaurantRoutes} />
      </Routes>
      <LangSelect />
    </div>
  );
}

export default App;
