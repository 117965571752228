import React, { useEffect, useState } from "react";
import { indexStore } from "../../store/indexStore";
import { useNavigate, useParams } from "react-router-dom";
import Spacer from "../Spacer";
import IsNotActive from "../IsNotActive/IsNotActive";
import { Header } from "../header";
import ProductsListMenu from "../ProductsListMenu/ProductsListMenu";
import RenderAndLoadingMenuCilrcles from "../LoadingComponent/RenderAndLoadingMenuCilrcles";

function ProductsTags() {
  const {
    loading,
    menus,
    selectProductAction,
    popularProducts,
    changeCategory,
    fetchMenusAction,
    newProducts,
    fetchPriceOffersProducts,
    priceOffersProducts,
  } = indexStore();
  const params = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  const handelOnClickProduct = (item) => {
    navigate(`${item.id}`);
    selectProductAction("");
  };
  const handleSelectChange = (id) => {
    changeCategory(0);
    navigate(`/${params.domain}/${params.branch_id}/${id}`);
  };

  useEffect(() => {
    if (menus.length === 0) {
      fetchMenusAction(params).then(() => {
        fetchPriceOffersProducts(params.branch_id);
      });
    }
  }, []);

  const productTag = window.location.href.split("/").pop();

  useEffect(() => {
    if (productTag === "popular") {
      setProducts(popularProducts);
    } else if (productTag === "new") {
      setProducts(newProducts);
    } else if (productTag === "offers") {
      setProducts(priceOffersProducts);
    }

    if (menus.length !== 0) {
      const activeElement = document.querySelector(".active");
      if (activeElement) {
        activeElement.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest", // Prevents vertical scrolling
        });
      }
    }
  }, [productTag, popularProducts, newProducts]);
  //   console.log(products);
  return (
    <IsNotActive>
      <title>{params.domain.toUpperCase()}</title>
      <Header url={`/${params.domain}/${params.branch_id}`} />

      <div className="bg-background flex-grow  p-4">
        <RenderAndLoadingMenuCilrcles
          loading={loading}
          menus={menus}
          selectedMenu={productTag}
          handleSelectChange={handleSelectChange}
        >
          <ProductsListMenu
            products={products}
            handelOnClickProduct={handelOnClickProduct}
            loading={loading}
          />
        </RenderAndLoadingMenuCilrcles>
      </div>
    </IsNotActive>
  );
}

export default ProductsTags;
