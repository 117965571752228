import React from "react";

import { IMG_URl } from "../../common/img_url";
import { indexStore } from "../../store/indexStore";
import Visibility from "../utils/Visibility";
import { useTranslation } from "react-i18next";
import Spacer from "../Spacer";

function ProductRow(props) {
  const { name, description } = indexStore();
  const { t } = useTranslation();

  return (
    <Visibility visible={!props.item.hidden}>
      <div
        className={`flex shadow-card-shadow rounded-md p-3 gap-2  ${
          props.displayWaygrid ? "flex-col" : "flex-row"
        }`}
        onClick={() => props.onClick(props.item)}
        key={props.key}
      >
        <img
          src={`${IMG_URl}${props.item.image}`}
          alt="img"
          className={`object-cover rounded-md aspect-square ${
            !props.displayWaygrid ? "min-w-24 w-24 " : "w-full  "
          }`}
        />
        <ProductMed
          item={props.item}
          t={t}
          name={name}
          description={description}
        />
        <Spacer flex />
        <ProductPrice
          displayWaygrid={props.displayWaygrid}
          item={props.item}
          name={name}
        />
      </div>
    </Visibility>
  );
}

export default ProductRow;

const ProductMed = ({ item, t, name, description }) => {
  return (
    <div className="flex flex-col justify-between gap-2">
      <div className="flex flex-col gap-1">
        <p className="text-xs text-onBackground font-bold text-ellipsis line-clamp-1">
          {item[name]}
        </p>
        <p className="text-xs text-onBackground text-ellipsis line-clamp-2">
          {item[description]}
        </p>
      </div>
      <ProductTags item={item} t={t} />
    </div>
  );
};
const ProductTags = ({ item, t }) => {
  const tagClass = `text-xs px-2 py-1 bg-primary text-onPrimary rounded-md `;
  return (
    <Visibility visible={item.new || item.unavailable || item.common}>
      <div className="flex gap-2 flex-wrap ">
        <Visibility visible={item.new}>
          <p className={tagClass}>{t("gloabl.new")}</p>
        </Visibility>
        <Visibility visible={item.common}>
          <p className={tagClass}>{t("gloabl.common")}</p>
        </Visibility>
        <Visibility visible={item.unavailable}>
          <p className={tagClass}>{t("gloabl.unavailable")}</p>
        </Visibility>
      </div>
    </Visibility>
  );
};

const ProductPrice = ({ displayWaygrid, item, name }) => {
  return (
    <div
      className={`flex shrink-0 justify-end gap-1 text-xs font-semibold text-onBackground ${
        displayWaygrid ? "flex-row justify-between" : "flex-col "
      }`}
    >
      <p
        className={` ${
          item.price_offer
            ? "line-through decoration-primary decoration-solid decoration-2 "
            : ""
        }`}
      >
        {item.price} {name == "name_ar" ? "د" : "AED"}
      </p>
      <Visibility visible={item.price_offer}>
        <p className="text-primary font-bold">
          {item.price_offer} {name == "name_ar" ? "د" : "AED"}
        </p>
      </Visibility>
    </div>
  );
};
