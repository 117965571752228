import React from "react";
import Visibility from "../utils/Visibility";
import { indexStore } from "../../store/indexStore";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdBlockFlipped } from "react-icons/md";

function IsNotActive(props) {
  const { t } = useTranslation();
  const { style, lang, errorCode } = indexStore();
  const params = useParams();
  return (
    <div className="flex flex-col flex-grow">
      <Visibility visible={errorCode == 403}>
        <title>{params.domain.toUpperCase()}</title>
        <div className="flex flex-col items-center justify-center gap-4">
          {t("gloabl.isNotActive")}
          <MdBlockFlipped className="text-6xl my-3" />
        </div>
      </Visibility>
      <Visibility visible={!errorCode}>{props.children}</Visibility>
    </div>
  );
}

export default IsNotActive;
