import React, { useState } from "react";
import Iterator from "../utils/Iterator";
import Visibility from "../utils/Visibility";
import { useTranslation } from "react-i18next";

function OptionsProduct({ options, name, price }) {
  const { t } = useTranslation();

  const [totals, setTotals] = useState([]);
  const total = totals.reduce((a, b) => a + b, 0);

  return (
    <div className="flex flex-col gap-3 text-onBackground">
      <h6 className="font-bold">{t("gloabl.options")} </h6>
      <div className="flex flex-col gap-2">
        <Iterator
          data={options}
          render={(e, i) => (
            <OptionsList
              option={e}
              key={e.id}
              name={name}
              onPriceChange={(price) =>
                setTotals((prev) => {
                  const clone = [...prev];
                  clone[i] = price;
                  return clone;
                })
              }
            />
          )}
        />
      </div>
      <div className="border-b-2 border-onBackground"></div>
      <p className="flex gap-1 justify-end text-[15px] ">
        <span className="font-bold">{t("gloabl.totalPrice")}:</span>{" "}
        <spn className="font-semibold ">
          {total + price}
          {name === "name_ar" ? " د" : " AED"}
        </spn>
      </p>
    </div>
  );
}
const OptionsList = ({ option, name, onPriceChange }) => {
  const [selectedOption, setSelectedOption] = useState();

  const clickHandle = (subOption) => {
    if (selectedOption != undefined) {
      if (selectedOption.id === subOption.id) {
        onPriceChange(0);
        setSelectedOption(undefined);
      } else {
        onPriceChange(subOption.price);
        setSelectedOption(subOption);
      }
    } else {
      onPriceChange(subOption.price);
      setSelectedOption(subOption);
    }
  };

  return (
    <div className="px-1 flex flex-col">
      <p className="text-base font-bold">{option[name]} </p>
      <Visibility visible={option.subOption && option.subOption.length > 0}>
        <div className="px-2 flex flex-col gap-1 ">
          <Iterator
            data={option.subOption}
            render={(e, i) => (
              <SubOptionsList
                subOption={e}
                name={name}
                option={option}
                checked={selectedOption && selectedOption.id === e.id}
                onClick={clickHandle}
              />
            )}
          />
        </div>
      </Visibility>
    </div>
  );
};

const SubOptionsList = ({ subOption, option, name, onClick, checked }) => {
  return (
    <div
      className="cursor-pointer flex items-center gap-3"
      onClick={() => onClick(subOption)}
    >
      <input
        className={`accent-primary border-dotted appearance-none w-4 h-4 border-2 border-onBackground rounded-50H ${
          checked ? "bg-primary" : ""
        }`}
        type="radio"
        name={`option_${option.id}`}
        value={subOption.id}
        checked={checked}
      />
      <div className="w-full flex justify-between">
        <p className="">{subOption[name]}</p>
        <Visibility visible={subOption.price}>
          <p className="w-1/6 flex" dir="ltr">
            <Visibility visible={subOption.price > 0}>
              <span className=""> + </span>
            </Visibility>
            <span className=""> {subOption.price}</span>
          </p>
        </Visibility>
      </div>
    </div>
  );
};
export default OptionsProduct;
