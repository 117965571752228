import React from "react";
import Layout from "../Layout";
import TWH1 from "../../../assets/TWH-1.png";
import TWH2 from "../../../assets/TWH-2.png";
import TWH3 from "../../../assets/TWH-3.png";
import TWH4 from "../../../assets/TWH-4.png";
import TWH5 from "../../../assets/TWH-5.png";
import TWH6 from "../../../assets/TWH-6.png";
import TWH7 from "../../../assets/TWH-7.png";
import TWH8 from "../../../assets/TWH-8.png";
import TWH9 from "../../../assets/TWH-9.png";
import TWH10 from "../../../assets/TWH-10.png";
import Iterator from "../../utils/Iterator";
import { useTranslation } from "react-i18next";
function HomePage() {
  const { t } = useTranslation();
  const properitesList = properites(t);
  return (
    <Layout title={`TWh Menu`}>
      <div className=" pb-7 pt-2">
        <section className="bg-primary text-onPrimary  py-10 rounded mb-2">
          <div className="flex flex-col gap-4 items-center">
            <h2 className="text-3xl font-bold mb-4">
              {t("gloabl.getOfferNow")}
            </h2>
            <p className="text-xl mb-6">
              {t("gloabl.getUpTo")}
              <span className="text-MainBlue" dir="ltr">
                20%
              </span>
            </p>

            <div className="flex justify-center items-center gap-4">
              <span className="text-2xl line-through text-gray-400">
                119 {t("gloabl.AED")}
              </span>
              <span className="text-4xl font-bold text-MainBlue">
                95 {t("gloabl.AED")}
              </span>
            </div>

            <a
              href="tel:+971 52 840 1112"
              dir="ltr"
              target="_blank"
              rel="noreferrer"
              className="mt-8 px-6 py-3 bg-MainBlue text-onPrimary font-semibold rounded hover:bg-yellow-600 transition duration-300"
            >
              {t("gloabl.getOffer")}
            </a>
          </div>
        </section>
        <section className=" px-5 flex flex-col gap-7 md:flex-row  justify-between md:items-center">
          <div className="md:max-w-maxWidthHalf flex flex-col gap-3">
            <h2 className="text-4xl font-bold whitespace-pre-line">
              {t("HomePage.introduction")}
            </h2>
            <p className="text-sm text-gray-600 whitespace-pre-line">
              {t("HomePage.introductionParagraph")}
            </p>
            <div className="flex gap-x-2 gap-y-3 flex-wrap">
              <Iterator
                data={properitesList}
                render={(e, i) => (
                  <a
                    className="border-2 border-black rounded-md px-3 py-1 font-semibold"
                    href={`#${e.id}`}
                    key={i}
                  >
                    {e.name}
                  </a>
                )}
              />
            </div>
          </div>
          <div className="flex justify-center items-center md:w-1/3">
            <img className="m-auto" src={TWH2} alt="Hero" />
          </div>
        </section>

        <Iterator
          data={properitesList}
          render={(e, i) => (
            <section
              key={i}
              className={`flex p-5 ${
                i % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
              }  justify-between md:items-center flex-col py-5 gap-7`}
              id={e.id}
            >
              <div className="md:max-w-maxWidthHalf flex flex-col gap-3">
                <h3 className="text-4xl font-bold">{e.name}</h3>
                <p className="text-base text-gray-600">{e.description}</p>
              </div>
              <img className=" md:w-1/3" src={e.img} alt={e.name} />
            </section>
          )}
        />
      </div>
    </Layout>
  );
}

export default HomePage;

const properites = (t) => [
  {
    id: "multiple-languages",
    name: t("HomePage.multipleLanguagesTitle"),
    img: TWH1,
    description: t("HomePage.multipleLanguagesParagraph"),
  },
  {
    id: "multiple-Separate-Branches",
    name: t(`HomePage.multipleAndSeparateBranchesTitle`),
    img: TWH3,
    description: t(`HomePage.multipleAndSeparateBranchesParagraph`),
  },
  {
    id: "design-customization",
    name: t("HomePage.designCustomizationTitle"),
    img: TWH4,
    description: t("HomePage.designCustomizationParagraph"),
  },
  {
    id: "easy-update",
    name: t("HomePage.easyUpdateTitle"),
    img: TWH5,
    description: t("HomePage.easyUpdateParagraph"),
  },
  {
    id: "tags-and-offers",
    name: t("HomePage.tagsAndOffersTitle"),
    img: TWH6,
    description: t("HomePage.tagsAndOffersParagraph"),
  },
  {
    id: "options-and-add-ons",
    name: t("HomePage.optionsAndAddOnsTitle"),
    img: TWH7,
    description: t("HomePage.optionsAndAddOnsParagraph"),
  },
  {
    id: "product-sorting",
    name: t("HomePage.productSortingTitle"),
    img: TWH9,
    description: t("HomePage.productSortingParagraph"),
  },
  {
    id: "social-media-integration",
    name: t("HomePage.socialMediaIntegrationTitle"),
    img: TWH8,
    description: t("HomePage.socialMediaIntegrationParagraph"),
  },
  {
    id: "customer-support",
    name: t("HomePage.customerSupportTitle"),
    img: TWH10,
    description: t("HomePage.customerSupportParagraph"),
  },
];
