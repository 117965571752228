export const localeEn = {
  gloabl: {
    details: "Details",
    selectMenu: "Select a menu",
    select: "select...",
    search: "search...",
    new: "New",
    common: "Popular",
    unavailable: "Unavailable",
    connectUs: "Connect us ",
    branchInfo: "Branch information ",
    changeLang: "Change language ",
    isNotActive: "The resturant is not active ",
    options: "Options :",
    ProducedBy: "Powered by  ",
    totalPrice: "Total price",
    branches: "Branches",
    ourBranches: "Our Branches",
    welcome: "Welcome to ",
    getOfferNow: "Get Offer Now",
    getUpTo: "Get up to ",
    getOffer: "Get Offer",
    AED: "AED ",
  },

  //////////////////////////////////////////////////
  pages: {
    contact: "contact",
    termsOfService: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    aboutUs: "About Us",
    home: "Home",
    notFound: "Not Found",
  },
  HomePage: {
    introduction: `Create Your
Digital Menu Easily!`,
    introductionParagraph: `Transform your restaurant or cafe with our customizable digital menu.
    Just scan a QR code to access a beautifully designed.`,
    /////////////////////////////////
    multipleLanguagesTitle: "Multiple languages",
    multipleLanguagesParagraph: `Menus available in both Arabic and English cater to a diverse audience, enhancing accessibility and customer satisfaction in multicultural regions like the UAE. Offering multilingual menus helps restaurants and cafes communicate effectively with both local and international customers, ensuring they can understand the menu options clearly.`,
    /////////////////////////////////
    multipleAndSeparateBranchesTitle: `Multiple & separate branches`,
    multipleAndSeparateBranchesParagraph: `In a restaurant management system, the concept of multiple and separate branches allows a single restaurant brand to manage several physical locations (branches), each of which can operate independently while still being part of the same overall brand. This setup is particularly useful for restaurant chains and franchises. `,
    /////////////////////////////////
    designCustomizationTitle: "Design customization",
    designCustomizationParagraph: `Ensure that your digital menu aligns seamlessly with your restaurant's brand identity. Customize every aspect of the menu to reflect the unique style and atmosphere of your establishment, like colors layout and imagery.`,
    /////////////////////////////////
    easyUpdateTitle: "Easy menu updates",
    easyUpdateParagraph: `Managing a restaurant menu can be challenging, especially when it requires frequent changes. With our digital menu system, you have full control over your menu at all times, offering a range of benefits. No need to reprint QR code.`,
    /////////////////////////////////
    tagsAndOffersTitle: "Tags & Offers",
    tagsAndOffersParagraph: `Enhance your menu by tagging products as popular, new, or unavailable. Easily create and manage special offers to attract and engage customers.`,
    /////////////////////////////////
    optionsAndAddOnsTitle: "Options & Add-ons",
    optionsAndAddOnsParagraph: `Enhance your dining experience with customizable selections. Choose from a variety of sizes, sauces, and additional options to perfectly tailor your meal to your taste. Elevate your dish with our flexible add-ons for a unique flavor combination every time.`,
    /////////////////////////////////
    productSortingTitle: "Product Sorting",
    productSortingParagraph: `Product Sorting allows users to organize items systematically, facilitating easy navigation and prioritizing featured products at the top for enhanced visibility and accessibility.`,
    /////////////////////////////////
    socialMediaIntegrationTitle: "Social Media",
    socialMediaIntegrationParagraph: `To optimize your restaurant's digital footprint, seamless social media integration ensures effortless connection and updates via direct links to your social platforms. Interactive maps simplify navigation, enhancing convenience for patrons to locate your restaurant and boosting overall customer satisfaction.`,
    ///////////////////////////////////
    customerSupportTitle: `Customer Support`,
    customerSupportParagraph: `Receive personalized assistance to optimize your menu experience. Our dedicated support ensures quick resolutions to any queries or issues, ensuring your menu remains dynamic and user-friendly.`,
  },
  contact: {
    sendMessage: "Send Message",
    name: "Name",
    email: "Email",
    subject: "Subject",
    message: "Message",
    send: "Send",
    phone: "Phone",
  },

  /////////////////////////////////
  PrivacyPolicy: {
    title: "Privacy Policy",
    updateDate: "Last updated: June 23, 2024",
    company: "Data Controller | Owner: TWH",
    introduction: `This privacy policy (“Privacy Policy”) explains to you how we collect, use, and share information and Personal Data that we obtain through your use of the internet sites, applications, and online services (“Services”) that are operated by, controlled by, or affiliated with TWH including twhmenu.com (collectively, “TWH”, “we”, “us” or “our”). This Privacy Policy only covers information and Personal Data collected through the Services and via direct communications between you and TWH, and does not cover any information collected at any other website, application, or otherwise by us (unless specifically stated), including when you call us, write to us, or communicate with us in any manner other than through the Services. By using the Services, you consent to such use of your information and Personal Data and agree to the terms of this Privacy Policy.
We will only process your Personal Data in accordance with applicable data protection and privacy laws. For the purposes of UK and EU data protection legislation, the data controller is TWH.`,
    //////////////////////////////////////////////////////////////////
    InformationWeAutomaticallyCollectName:
      "Information We Automatically Collect",
    InformationWeAutomaticallyCollectDes: `We and our third-party service providers (including any third-party content, advertising, and analytics providers) may automatically collect certain information from your device or web browser when you interact with the Services to help us understand how our users use the Services and to target advertising to you (which we will refer to in this Privacy Policy collectively as “Usage Data”). For example, each time you visit the Services we and our third-party service providers may automatically collect your IP address, mobile device identifier or other unique identifier, browser and computer type, access time, the Web page you came from, the URL you go to next, the Web page(s) that you access during your visit and your interaction with content or advertising on the Services.`,
    //////////////////////////////////////////////////////////////////////////////////
    InformationYouChoosetoSubmitName: "Information You Choose to Submit",
    InformationYouChoosetoSubmitDes: `You can visit the Services without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual (which we will refer to in this Privacy Policy collectively as “Personal Data”). If, however, you wish to register to become a member of the Services, you are required to provide certain Personal Data (for example, your name, address, e-mail address, telephone number and information regarding your particular business), and financial and credit card information, and you must also provide a user name and password. We use your Personal Data to fulfill your requests for products and services, to improve our Services, to contact you from time to time, with your consent, about us and our products and services, and as otherwise described in this Privacy Policy. We refer collectively to all information we collect that is not Personal Data, including Usage Data, demographic data, and de-identified Personal Data, as “Non-Personal Data”. If we combine Non-Personal Data with Personal Data, we will treat the combined information as Personal Data under this Privacy Policy .
        
        You also may choose to submit photos, descriptions, registered logos, icons, brochures, advertising signs, brochures, visual materials, or presentation (which we will refer to in this Privacy Policy collectively as “User Content”). Personal Data, Non-Personal Usage Data, and User Content are referred to in this Privacy Policy collectively as (“User Information”).`,
    ////////////////////////////////////////////////////////
    TransactionInformationName: "Transaction Information",
    TransactionInformationDes: `When you take orders using our Services, we may collect information about the transaction, including date, time, and amount of the transaction, the merchant’s location, and description, any photo, and description you choose to associate with the transaction, the names of the customer.`,
    /////////////////////////////////////////////////////////////////////////
    HowWeUseTheInformationName: `How We Use The Information`,
    HowWeUseTheInformationDes: `We may use the information we collect, including Usage Data, Personal Data, and User Content:
  
  to enable you to use our Services, to create an account or profile, to process the information you provide via our Services (including verifying that your email address is active and valid) and to process your transactions;
  to provide related customer service and care, including responding to your questions, complaints, or comments and sending surveys (with your consent) and processing survey responses;
  to provide you with information, products, or services that you have requested;
  with your consent, to provide you with information, products, or services that we otherwise believe will interest you, including special opportunities from us and our third-party partners;
  to tailor content, recommendations, and advertisements we and third parties display to you, both on the Services and elsewhere online;
  for internal business purposes, such as to improve our Services; to administer and process contests, sweepstakes, and promotions;
  to contact you with administrative communications and, in our discretion, changes to our Privacy Policy, Terms of Use, or any of our other policies;
  to comply with regulatory and legal obligations; and
  for purposes as disclosed at the time you provide your information, with your consent, and as further described in this Privacy Policy.
  TWH reserves the right to utilize screenshots of User Content and/or customer logos, exclusive of confidential or proprietary data, for marketing or promotional purposes.
 `,
    ////////////////////////////////////////////////////////////////////////
    CookiesTrackingName: "Cookies/Tracking",
    CookiesTrackingDes: `In addition, we use information collected online through cookies, web beacons and other automated means for purposes such as (i) customizing your visits to our websites and apps, (ii) delivering content (including advertising) tailored to our your’ interests and the way you browse our websites and apps, and (iii) managing our business. We may supplement data we collect through automated means with information about you with content that may be of interest to you.
  
  Most browsers are initially set up to accept cookies. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent; however, if you reject cookies, you will not be able to sign in to the Services or take full advantage of our Services.
  
  You can find more information about cookies and how they work, what cookies have been set on your computer or mobile device and how to manage and delete them at www.allaboutcookies.org.`,
    //////////////////////////////////////////////////////////////////
    InformationSharingPracticeName: "Information Sharing Practice",
    InformationSharingPracticeDes: `We will not sell, share, or otherwise distribute your User Information to third parties except as provided in this Privacy Policy.
  
  Service Providers. From time to time, we may enter relationships with third parties who provide services to us (for example, analytics companies, advertisers and ad agencies, data management, and storage services or credit card processing services, sweepstakes or contest prize fulfillment). In those circumstances, we disclose User Information so that such service providers may perform those services. These service providers are only permitted to use your User Information to the extent necessary to enable them to provide their services to us. They are required to follow our express instructions and to comply with appropriate security measures to protect your User Information.
  
  We reserve the right, and you hereby expressly authorize us, to share User Information: (1) in response to subpoenas, court orders, or legal process, or to establish, protect, or exercise our legal rights or defend against legal claims; (2) if we believe it is necessary in order to investigate, prevent, or take action regarding illegal activities, fraud, or situations involving potential threats to the safety of any person or property; (3) if we believe it is necessary to investigate, prevent, or take action regarding significant abuse of the Services infrastructure or the Internet in general (such as voluminous spamming, denial of service attacks, or attempts to compromise the security of information); (4) to protect and defend our legal rights or property, our services or their users, or any other party, and to protect the health and safety of our users or the general public; and (5) to our parent company, subsidiaries, joint ventures, or other companies under common control with us (in which case we will require such entities to honor this Privacy Policy).
  
  In the event, we go through a business transition (such as a merger, acquisition by another company, bankruptcy, or sale of all or a portion of our assets, including, without limitation, during any due diligence process), your User Information will likely be among the assets transferred. By providing your User Information, you agree that we may transfer such information in those circumstances without your further consent. We will provide notice before your User Information is transferred and becomes subject to a different Privacy Policy.`,
    //////////////////////////////////////////////////////////////////
    OptOutFromCommunicationsName: `Opt-Out from Communications`,
    OptOutFromCommunicationsDes: `We offer you the opportunity to manage your communications with us. Even after subscribing to one or more newsletters and opting into one or more offers to receive marketing and promotional communications from us or our third-party partners, users may elect to modify their preferences by emailing us at info@twhmenu.com to unsubscribe. Please be aware that if you wish to remove yourself from a newsletter and other marketing emails from third parties that you consented to through the Services, you must do so by contacting the relevant third-party. Even if you do opt-out of marketing emails, we reserve the right to send you transactional and administrative emails including those related to the Services, service announcements, notices of changes to this Privacy Policy or other Services policies, and to contact you regarding any services you have ordered.`,
    //////////////////////////////////////////////////////////////////
    ModifyingAndDeletingYourPersonalDataName: `Modifying and Deleting Your Personal Data`,
    ModifyingAndDeletingYourPersonalDataDes: `You may access the information we hold about you. If you wish to exercise this right, please contact us using the details in the Contact Us section below. To help protect your privacy and maintain security, we will take steps to verify your identity before granting you access to the information. If you would like to update, correct, modify or delete from our database any Personal Data you previously submitted to us, please let us know by accessing and updating your profile. If you delete certain information, you may not be able to order services in the future without re-submitting such information. We will comply with your request as soon as reasonably practicable. Also, please note that
  we will maintain Personal Data in our database whenever we are required to do so by law.  
  Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting such change or deletion (for example, when you enter a promotion, you may not be able to modify or delete the Personal Data provided until after the completion of such development). We will retain your Personal Data for the period necessary to fulfill the purposes outlined in this Policy unless a longer retention period is required or permitted by law.`,
    //////////////////////////////////////////////////////////////////
    ExercisingDataProtectionRightsName: `Exercising Data Protection Rights`,
    ExercisingDataProtectionRightsDes: `In order to exercise your data protection rights, you may contact us as described in the footer below. We take each request seriously. We will comply with your request to the extent required by applicable law. We will not be able to respond to a request if we no longer hold your Personal Data. If you feel that you have not received a satisfactory response from us, you may consult with the data protection authority in your country.
  For your protection, we may need to verify your identity before responding to your request, such as verifying that the email address from which you send the request matches your email address that we have on file. If we no longer need to process Personal Data about you in order to provide our Services or our Sites, we will not maintain, acquire or process additional information in order to identify you for the purpose of responding to your request.`,
    //////////////////////////////////////////////////////////////////
    ConsentToTransferName: `Consent To Transfer`,
    ConsentToTransferDes: `We are a global company and have databases in different countries. We may transfer your data to one of our databases outside your country of domicile, potentially including countries that may not require an adequate level of protection for your Personal Data compared with that provided in your country. By using the Services or providing us with any information, you consent to this transfer, processing, and storage of your information in a jurisdiction in which the privacy laws may not be as comprehensive as those in the country where you reside or are a citizen. We will take all steps reasonably necessary to ensure that your data is treated securely and by this Privacy Policy.`,
    ///////////////////////////////////////////////////////////////////
    RetentionofDataName: `Retention of Data `,
    RetentionofDataDes: `The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document, and then you can always request that the Data Controller suspend or remove the data. We collect and process Financial Account Information from you using third-party PCI-compliant service providers when you subscribe to the Service.`,
    //////////////////////////////////////////////////////////////////
    ChildrenName: `Children`,
    ChildrenDes: `The Services are intended for a general audience and are not intended for and may not be used by children under the age of 16. We do not knowingly collect information from children under the age of 16 and we do not target the Services to children under the age of 16. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us using the details in the Contact Us section below. We will delete such information from our files as soon as reasonably practicable.`,
    //////////////////////////////////////////////////////////////////
    CaliforniaResidentsPrivacyRightsName: `California Residents – Privacy Rights`,
    CaliforniaResidentsPrivacyRightsDes: `As stated elsewhere in this Privacy Policy, we do not share Personal Data with third parties for their direct marketing purposes, as defined by California Civil Code Section 1798.83, unless we give you a choice (opt-in or opt-out) before sharing with those third parties.  
  If you are a California resident and you have questions about our practices concerning sharing information with third parties for their direct marketing purposes and your ability to exercise choice, please contact us at info@twhmenu.com. You must put the statement “Your California Privacy Rights” in the body of your request, as well as your name, street address, city, state, and zip code. In the body of your request, please provide enough information for us to determine if this applies to you. Please note that we will not accept inquiries via telephone, email, or by facsimile, and we are not responsible for notices that are not labeled or sent properly, or that do not have complete information.`,
    //////////////////////////////////////////////////////////////////
    ChangesName: `Changes`,
    ChangesDes: `We may change this Privacy Policy from time to time, and if we do, the most current version will be available at this page with the “Last updated” date indicating when it was last updated. You are responsible for regularly reviewing this Privacy Policy before using the Services.`,
    //////////////////////////////////////////////////////////////////
    DataSecurityName: `Data Security`,
    DataSecurityDes: `We have implemented commercially reasonable security measures to help protect your Personal Data from loss, misuse, or unauthorized access or disclosure; unfortunately, however, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your Personal Data, we cannot ensure its security. You use the Services and provide us with information on your initiative and risk. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us using the details in the Contact Us section below.`,
    ////////////////////////////////////////////////////////////////////
    HowtoContactUsName: `How to Contact Us`,
    HowtoContactUsDes: `If you have any questions about this Privacy Policy, please feel free to contact us by email at info@twhmenu.com.`,
  },
  ////////////////////////// END PRIVACY POLICY ///////////////////////////

  //////////////////////////////////////////////////////////////
  Terms: {
    title: `Terms of Service`,
    updateDate: "Last updated: June 25, 2024",
    company: "Thank you for choosing to use our services.",
    introduction: `These Terms of Service (“Terms”, “Terms of Service”) govern your relationship with TWH sites, applications and online services (the “Service”) operated by TWH (collectively, “TWH”, “us”, “we”, or “our”). Please read these Terms of Service carefully before using our Service.

Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.

These Terms of Service are subject to change by TWH at any time and at our discretion without notice. Your use of this website after any changes are implemented constitutes your acceptance of the changes. As a result, we encourage you to consult the terms each time you use this website.`,
    //////////////////////////////////////////////////////////////
    PrivacyandCommunicationsName: `Privacy and Communications`,
    PrivacyandCommunicationsDes: `You acknowledge and agree that TWH may occasionally send you communications regarding your account or the Service via email or any communication media. To keep your contact information up to date is under your responsibility. Otherwise, it will be deemed to have delivered the notifications made to the existing e-mail address. The communication purpose may be marketing, advertising, promoting or legal notifications. See the Privacy Policy, which is incorporated into this Agreement by reference. See the Privacy Policy, which is incorporated into this Agreement by reference.`,
    //////////////////////////////////////////////////////////////
    AbilitytoAcceptTermsofServiceName: `Ability to Accept Terms of Service`,
    AbilitytoAcceptTermsofServiceDes: `You affirm that you are either more than 18 years of age, or an emancipated minor, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms of Service, and to abide by and comply with these Terms of Service.

The Site is not available to minors. If you are a minor, please do not use the Site.`,
    ////////////////////////////////////////////////////////////////
    AcceptableUseandConductName: `Acceptable Use and Conduct`,
    AcceptableUseandConductDes: `Our Software and Service may be used only for personal or commercial use, which must be in compliance with all applicable laws, rules and regulations and must not infringe or violate third party rights. You represent, warrant and agree that you will not use the Service for any purpose or in any manner that is not authorized by these Terms of Service. It is your responsibility to ensure that your use of the Service complies with these Terms of Service and to seek prior, express written permission from TWH for any uses not permitted or not expressly specified in these Terms of Service.`,
    ////////////////////////////////////////////////////////////////
    PurchasesName: `Purchases`,
    PurchasesDes: `If you wish to purchase any product or service made available through the Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.

You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.

By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases.

We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons. We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.`,
    //////////////////////////////////////////////////////////////////

    AvailabilityErrorsandInaccuraciesName: `Availability, Errors and Inaccuracies`,
    AvailabilityErrorsandInaccuraciesDes: `We are constantly updating our offerings of products and services on the Service. The products or services available on our Service may be mispriced, described inaccurately, or unavailable, and we may experience delays in updating information on the Service and in our advertising on other web sites.

We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.`,
    ////////////////////////////////////////////////////////////////
    SubscriptionsName: `Subscriptions`,
    SubscriptionsDes: `Some parts of the Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.

At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or TWH cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting TWH customer support team.

A valid payment method, including credit card, is required to process the payment for your Subscription. You shall provide TWH with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize TWH to charge all Subscription fees incurred through your account to any such payment instruments.

Should automatic billing fail to occur for any reason, TWH will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.`,
    //////////////////////////////////////////////////////////////////
    FreeTrialName: `Free Trial`,
    FreeTrialDes: `TWH may, at its sole discretion, offer a Subscription with a free trial for a limited period of time (“Free Trial”). You may be required to enter your billing information in order to sign up for the Free Trial. If you do enter your billing information when signing up for the Free Trial, you will not be charged by TWH until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.

At any time and without notice, TWH reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.`,
    ////////////////////////////////////////////////////////////////
    FeeChangesName: `Fee Changes`,
    FeeChangesDes: `TWH, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.

TWH will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.

Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.`,
    //////////////////////////////////////////////////////////////////
    ChargebackRefundPolicyName: `Chargeback and Refund Policy`,
    ChargebackRefundPolicyDes: `TWH will not, except when required by law, issue cash refunds for early contract cancellation. If you have a question about charges made to your account, please contact us at info@twhmenu.com. If the charges were made in error, we will credit your account or credit card account for the appropriate amount.

Any customer who disputes a credit card payment that is found to be valid will be banned from use of the Service. Any past due fees and costs will be sent to collections. If our collection efforts fail, unpaid debts will be reported to all available credit reporting agencies.`,
    //////////////////////////////////////////////////////////////////
    PaymentProcessingName: `Payment Processing`,
    PaymentProcessingDes: `Payment processing services for Customers on our Service are provided by Stripe and are subject to the Stripe Connected Account Agreement, which includes the Stripe Terms of Service (collectively, the “Stripe Services Agreement”). By agreeing to these terms or continuing to operate as a Customer on our Service, you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a condition of our Service enabling payment processing services through Stripe, you agree to provide our Service accurate and complete information about you and your business, and you authorize our Service to share it and transaction information related to your use of the payment processing services provided by Stripe.`,
    //////////////////////////////////////////////////////////////////
    ContentName: `Content`,
    ContentDes: `Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.

By posting Content to the Service, you grant us the right and license to use, modify, perform, display, reproduce, and distribute such Content on and through the Service for marketing or promotional purposes. You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights.

You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.

We may, but have no obligation to, remove accounts and content containing what we determine as unlawful, offensive, threatening, defamatory, obscene or otherwise objectionable material. We will remove content that violates any party’s intellectual property or these Terms of Service. An account terminated by TWH will not be backed-up for any reason and will be immediately deleted from our servers.`,
    //////////////////////////////////////////////////////////////////
    AccountsName: `Accounts`,
    AccountsDes: `When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.

You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.`,
    //////////////////////////////////////////////////////////////////
    IntellectualPropertyName: `Intellectual Property`,
    IntellectualPropertyDes: `The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of TWH and its licensors. The Service is protected by copyright, trademark, and other laws. Except as expressly authorized by TWH, you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works from the Service. Use of the Service for any purpose not expressly permitted by these Terms of Service is strictly prohibited.

TWH, the TWH logo, other TWH trademarks, service marks, graphics, and logos used in connection with the Service are trademarks or registered trademarks of TWH. Other trademarks, service marks, graphics, and logos used in connection with the Service may be the trademarks of their respective owners. You are granted no right or license with respect to any of the aforesaid trademarks and any use of such trademarks.`,
    //////////////////////////////////////////////////////////////////
    TerminationName: `Termination`,
    TerminationDes: `We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.

All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.`,
    //////////////////////////////////////////////////////////////////
    DisclaimerName: `Disclaimer`,
    DisclaimerDes: `Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.

TWH, its subsidiaries, affiliates, and its licensors do not warrant that (a) the Service will function uninterrupted, secure or available at any particular time or location; (b) any errors or defects will be corrected; (c) the Service is free of viruses or other harmful components; or (d) the results of using the Service will meet your requirements.`,
    //////////////////////////////////////////////////////////////////
    LimitationofLiabilityName: `Limitation of Liability`,
    LimitationofLiabilityDes: `In no event shall TWH, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.`,
    //////////////////////////////////////////////////////////////////
    GoverningLawName: `Governing Law`,
    GoverningLawDes: `These Terms shall be governed and construed in accordance with the laws of [Your Country], without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.`,
    //////////////////////////////////////////////////////////////////
    ChangesName: `Changes`,
    ChangesDes: `We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.`,
    //////////////////////////////////////////////////////////////////
    ContactName: ``,
    ContactDes: `If you have any questions about these Terms, please contact us at info@twhmenu.com.`,
  },
};
