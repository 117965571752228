import React from "react";
import Iterator from "../utils/Iterator";

function LoadingComponent(props) {
  const arrayCount = Array.from({ length: props.count }, (x, i) => i + 1);
  const render = (e, i) => {
    return (
      <div
        key={i}
        className={`${props.className} relative overflow-hidden  transition-all duration-500 bg-[#d8d8d8]
           before:absolute before:content-[''] 
          before:h-full before:w-full before:bg-[linear-gradient(135deg,#d9d9d9_0%,rgba(0,0,0,0.1)_100%)]
           before:bg-no-repeat
           before:animate-shimmer before:animation-delay-200ms`}
        style={{
          width: props.width,
          height: props.height,
          borderRadius: props.radius,
          ...props.style,
        }}
      ></div>
    );
  };
  return (
    <div
      className=" flex p-5"
      style={{
        display: "flex",
        flexDirection: props.dir,
        gap: props.gap,
        padding: props.padding,
      }}
    >
      <Iterator data={arrayCount} render={render} />
    </div>
  );
}

export default LoadingComponent;
