import React from "react";
import imgL from "../../assets/TWH1-black.png";
import LangSelect from "../langSelect/langSelect";
import { useTranslation } from "react-i18next";
import { LuAlignJustify, LuGlobe, LuX } from "react-icons/lu";
import { indexStore } from "../../store/indexStore";

function HeaderPage() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { lang, changeLangBottomSheet } = indexStore();

  return (
    <div className="flex justify-between items-center shadow-sm px-4 relative bg-white">
      <a href="/" className="">
        <img width={150} height={90} src={imgL} alt="cl" className="p-2" />
      </a>
      <div className="flex gap-7  items-center   ">
        <div
          className={`flex gap-1 justify-between items-center p-2 cursor-pointer `}
          onClick={() => changeLangBottomSheet(true)}
        >
          {lang === "ar" ? "ع" : "EN"}
          <LuGlobe />
        </div>
        <div
          className={`flex gap-7 flex-col md:flex-row md:static absolute right-0 top-full z-0
            py-3 px-3 items-center transition-all w-full md:w-auto  md:shadow-none bg-white  shadow-md  md:visible md:opacity-100${
              open
                ? "translate-y-0 visible opacity-100 md:visible md:opacity-100"
                : "-translate-y-full invisible opacity-0 md:visible md:opacity-100 "
            }`}
        >
          <a className="text-lg font-semibold" href="/">
            {t("pages.home")}
          </a>
          <a className="text-lg font-semibold" href="/contact">
            {t("pages.contact")}
          </a>
        </div>

        <div className="md:hidden text-xl" onClick={() => setOpen(!open)}>
          {!open ? <LuAlignJustify /> : <LuX />}
        </div>
      </div>
    </div>
  );
}

export default HeaderPage;
