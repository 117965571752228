import React from "react";
import Iterator from "../utils/Iterator";
import { useTranslation } from "react-i18next";
import { socialMediaTypes } from "../../enum/socialMediaType";

function SocialMedia(props) {
  const { t } = useTranslation();
  const renderSocialMedia = (e, i) => {
    if (!e.value.startsWith("https://") && !e.value.startsWith("http://")) {
      e.value = "https://" + e.value;
    }
    return (
      <div className="flex gap-1 items-center">
        <span> {socialMediaTypes[e.type]}</span>
        <span className="">
          <a target="_blank" rel="noreferrer" href={e.value}>
            {e.name}
          </a>
        </span>
      </div>
    );
  };
  return (
    <div className="py-2 flex flex-col gap-3">
      <p className="text-lg font-medium"> {t("gloabl.connectUs")}:</p>
      <Iterator data={props.social} render={renderSocialMedia} />
    </div>
  );
}

export default SocialMedia;
