import React from "react";
import { LuPhone } from "react-icons/lu";
import { FaWhatsapp } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { FooterCopy } from "../footer";

function FooterPages() {
  const { t } = useTranslation();

  return (
    <div className=" flex gap-3 justify-between flex-wrap flex-col px-5 py-10  md:px-10 shadow-top-sm bg-gray-30 font-semibold ">
      <div
        className="flex flex-col gap-3 md:flex-row md:justify-between  text-xl "
        dir="ltr"
      >
        <div className="flex gap-2 items-center justify-center">
          <LuPhone />
          <div className="flex flex-col gap-1">
            <a
              href="tel:+971 52 840 1112"
              dir="ltr"
              target="_blank"
              rel="noreferrer"
            >
              +971 52 840 1112
            </a>
            <a
              href="tel:+971 55 440 0501"
              dir="ltr"
              target="_blank"
              rel="noreferrer"
            >
              +971 55 440 0501
            </a>
          </div>
        </div>
        <div className="flex gap-2 items-center justify-center">
          <FaWhatsapp />
          <div className="flex flex-col gap-1">
            <a
              href="https://wa.me///+971528401112?text=Hello%21+I+found+TWHMENU.com+and+I+want+to+create+digital+menu"
              dir="ltr"
              target="_blank"
              rel="noreferrer"
            >
              +971 52 840 1112
            </a>
            <a
              href="https://wa.me///+971554400501?text=Hello%21+I+found+TWHMENU.com+and+I+want+to+create+digital+menu"
              dir="ltr"
              target="_blank"
              rel="noreferrer"
            >
              +971 55 440 0501
            </a>
          </div>
        </div>
        <div className="flex gap-2 items-center justify-center">
          <MdOutlineEmail />
          <div className="flex flex-col gap-1">
            <a
              href="mailto:info@twhmenu.com"
              dir="ltr"
              target="_blank"
              rel="noreferrer"
            >
              info@twhmenu.com
            </a>
          </div>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.0849627424122!2d55.5302562248526!3d25.435422877558317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f7d5452e90a5%3A0x9897aa61b743bff2!2sTWH%20Interiors!5e0!3m2!1sar!2sae!4v1721483754105!5m2!1sar!2sae"
        className="w-full rounded-lg h-64 md:h-96 "
        style={{ border: 0 }}
        title="map"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <div>
        <FooterCopy />
        <div className="flex justify-center items-center gap-3">
          <a
            className="text-lg hover:underline"
            href="/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            {t("pages.termsOfService")}
          </a>
          <a
            className="text-lg  hover:underline"
            href="/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {t("pages.privacyPolicy")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterPages;
