import React from "react";
import LoadingComponent from "./LoadingComponent";
import Iterator from "../utils/Iterator";
import Spacer from "../Spacer";

function LoadingMenusProduct({ displayWaygrid }) {
  return (
    <div
      className={`flex gap-5 flex-col ${
        displayWaygrid
          ? "grid grid-cols-1  xs:grid-cols-2  md:grid-cols-3 gap-2 :"
          : ""
      }`}
    >
      <Iterator
        data={[1, 2, 3, 4]}
        render={() => (
          <div
            className={`flex shadow-card-shadow rounded-md p-3 gap-2  ${
              displayWaygrid ? "flex-col" : "flex-row"
            }`}
          >
            <LoadingComponent
              className={` aspect-square ${
                !displayWaygrid ? "min-w-24 w-24 " : "min-w-full w-full  "
              }`}
              count={1}
              padding={"0"}
              radius={"5px"}
            />
            <div className="flex flex-col justify-between gap-2">
              <div className="flex flex-col gap-1">
                <LoadingComponent
                  className={`min-w-28 w-28 h-3`}
                  count={1}
                  padding={"0"}
                />
                <LoadingComponent
                  className={`min-w-32 w-32 h-3`}
                  count={1}
                  padding={"0"}
                />
              </div>
            </div>
            <Spacer flex />
            <div
              className={`flex shrink-0 justify-end gap-1 text-xs font-semibold ${
                displayWaygrid ? "flex-row justify-between" : "flex-col "
              }`}
            >
              <LoadingComponent className={`w-5 h-5`} count={1} padding={"0"} />
            </div>
          </div>
        )}
      />
    </div>
  );
}

export default LoadingMenusProduct;
