import React from "react";

const Spacer = (props) => {
  return (
    <div
      style={
        props.flex ? { flex: 1 } : { width: props.width, height: props.height }
      }
    ></div>
  );
};

export default Spacer;
