import Spacer from "../../../../components/Spacer";
import React, { useEffect } from "react";
import { IMG_URl } from "../../../../common/img_url";
import { useParams } from "react-router-dom";
import { Header } from "../../../../components/header";
import { indexStore } from "../../../../store/indexStore";
import Visibility from "../../../../components/utils/Visibility";
import { useTranslation } from "react-i18next";
import OptionsProduct from "../../../../components/OptionsProduct/OptionsProduct";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import { Skeleton } from "../../../../components/ui/skeleton";
function Product() {
  const { t } = useTranslation();

  const {
    name,
    description,
    product,
    fetchProductByAction,
    itemLoading,
    getStyle,
  } = indexStore();
  const params = useParams();
  const paramsRequst = {
    domain: params.domain,
    branch_id: params.branch_id,
    menu_id: params.menu,
    product: params.product,
  };

  useEffect(() => {
    if (Object.keys(product).length == 0) {
      getStyle(paramsRequst);
    }
    fetchProductByAction(paramsRequst.product);
  }, []);
  const formattedDescription = product[description]?.replace(/\n/g, "<br/>");
  const tagClass = `text-sm px-2 py-1 bg-primary text-onPrimary rounded-md `;
  return (
    <div className="bg-background flex-grow">
      <title>{paramsRequst.domain.toUpperCase()}</title>

      <Visibility visible={!itemLoading}>
        <div className="z-[1] relative overflow-hidden flex flex-col gap-2 items-center">
          <Header backgroundColorfull={true} resName={params.domain} />
          <div className="absolute bg-primary -left-[100px] -right-[100px] -top-[100px] bottom-[25px] rounded-br-[500px] rounded-bl-[500px] z-[-1]"></div>
          <Spacer height={15} />
          <p className="text-onPrimary text-[28px] text-center py-2">
            {product[name]}
          </p>
          <Spacer height={10} />
          <img
            className="w-[55vw] aspect-square  object-cover"
            src={`${IMG_URl}${product.image}`}
            alt="logo"
          />
        </div>
        <Spacer height={20} />
        <div className="flex text-onBackground flex-col gap-4 justify-between p-4 rounded-2xl shadow-md m-7 bg-background  ">
          <Visibility
            visible={product.new || product.unavailable || product.common}
          >
            <div className="flex gap-1 flex-wrap">
              <Visibility visible={product.new}>
                <p className={tagClass}>{t("gloabl.new")}</p>
              </Visibility>
              <Visibility visible={product.common}>
                <p className={tagClass}>{t("gloabl.common")}</p>
              </Visibility>
              <Visibility visible={product.unavailable}>
                <p className={tagClass}>{t("gloabl.unavailable")}</p>
              </Visibility>
            </div>
          </Visibility>
          <p
            className=" text-sm font-semibold line-clamp-3"
            dangerouslySetInnerHTML={{ __html: formattedDescription }}
          ></p>
          <div className="text-[15px] font-semibold flex justify-end gap-[65%]">
            <p
              className={`price ${
                product.price_offer
                  ? "line-through decoration-primary decoration-solid decoration-2"
                  : ""
              }`}
            >
              {product.price} {name == "name_ar" ? "د" : "AED"}
            </p>
            <Visibility visible={product.price_offer}>
              <p className="price-offer">
                {product.price_offer} {name == "name_ar" ? "د" : "AED"}
              </p>
            </Visibility>
          </div>
          <Visibility visible={product.option && product.option.length > 0}>
            <OptionsProduct
              options={product.option}
              name={name}
              price={product.price_offer || product.price}
            />
          </Visibility>
          <Spacer height={4} />
        </div>
      </Visibility>
      <Visibility visible={itemLoading}>
        <RenderProduct />
      </Visibility>
    </div>
  );
}

export default Product;

const RenderProduct = () => {
  return (
    <>
      <div className="z-[1] relative overflow-hidden flex flex-col gap-2 items-center">
        <Skeleton className={"w-full h-16"} />
        <Skeleton className="absolute -left-[100px] -right-[100px] -top-[100px] bottom-[25px] rounded-br-[500px] rounded-bl-[500px] z-[-1]" />
        <Spacer height={15} />
        <Skeleton className={"w-48 h-10"} />
        <Spacer height={10} />
        <Skeleton className="w-[55vw] aspect-square  object-cover" />
      </div>
      <Spacer height={20} />
      <div className="flex text-onBackground flex-col gap-4 justify-between p-4 rounded-2xl shadow-md m-7 bg-background  ">
        <div className="flex gap-1 flex-wrap">
          <Skeleton className={"w-8 h-5"} />
          <Skeleton className={"w-8 h-5"} />
          <Skeleton className={"w-8 h-5"} />
        </div>
        <Skeleton className="w-48 h-5" />
        <Skeleton className={"w-8 h-5"} />

        <Spacer height={4} />
      </div>
    </>
  );
};
