import React from "react";
import Layout from "../Layout";
import { useTranslation } from "react-i18next";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { LuPhone } from "react-icons/lu";
import FormContactUs from "./FormContactUs";

function ContactUs() {
  const { t } = useTranslation();
  return (
    <Layout title={t("pages.contact")}>
      <div className="px-5 py-7">
        <h2 className="text-4xl font-bold text-center py-6 w-/12">
          {t("pages.contact")}
        </h2>
        <div className="flex flex-col md:flex-row- justify-center gap-10 md:gap-44">
          <div className="flex flex-col gap-10    text-xl " dir="ltr">
            <div className="flex flex-col gap-1 items-center">
              <p className="font-bold text-xl"> {t("contact.phone")}</p>
              <div className="flex gap-2 items-center ">
                <LuPhone />
                <div className="flex flex-col gap-1">
                  <a
                    href="tel:+971 52 835 0086"
                    dir="ltr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +971 52 835 0086
                  </a>
                  <a
                    href="tel:+971 55 440 0501"
                    dir="ltr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +971 55 440 0501
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1 items-center ">
              <p className="font-bold text-xl">Whatsapp</p>
              <div className="flex gap-2 items-center ">
                <FaWhatsapp />
                <div className="flex flex-col gap-1">
                  <a
                    href="https://wa.me///+971528350086?text=Hello%21+I+found+TWHMENU.com+and+I+want+to+create+digital+menu"
                    dir="ltr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +971 52 835 0086
                  </a>
                  <a
                    href="https://wa.me///+971554400501?text=Hello%21+I+found+TWHMENU.com+and+I+want+to+create+digital+menu"
                    dir="ltr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +971 55 440 0501
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1 items-center ">
              <p className="font-bold text-xl"> {t("contact.email")}</p>
              <div className="flex gap-2 items-center ">
                <MdOutlineEmail />
                <div className="flex flex-col gap-1">
                  <a
                    href="mailto:info@twhmenu.com"
                    dir="ltr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    info@twhmenu.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <FormContactUs />
        </div>
      </div>
    </Layout>
  );
}

export default ContactUs;
