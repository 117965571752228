import { axiosInstance } from "../axios-instance";
const getBranches = "getBranch";
const getMenu = "getMenu";
const getCategory = "getCategory";
const getProduct = "getProduct";
const getProductById = "getProductById";
const getOption = "getOption";
const getStyle = "getStyle";
const getSocialMedia = "getSocialMedia";
const getProductCommon = "getProductCommon";
const getProductNew = "getProductNew";
const getProductPriceOffer = "getProductPriceOffer";
const menuServices = {
  getBranches: (payload) => axiosInstance().post(`${getBranches}`, payload),
  getMenu: (payload) => axiosInstance().post(`${getMenu}`, payload),
  getCategory: (payload) => axiosInstance().post(`${getCategory}`, payload),
  getProduct: (payload) => axiosInstance().post(`${getProduct}`, payload),
  getProductById: (payload) =>
    axiosInstance().post(
      `${getProductById}/${payload.productId}&${payload.visit}`
    ),
  getOption: (payload) => axiosInstance().post(`${getOption}`, payload),
  getStyle: (payload) => axiosInstance().post(`${getStyle}`, payload),
  getSocial: (payload) => axiosInstance().post(`${getSocialMedia}`, payload),
  ////////////////////////////////////////////////////////
  getProductCommon: (branch_id) =>
    axiosInstance().get(`${getProductCommon}/${branch_id}`),
  getProductNew: (branch_id) =>
    axiosInstance().get(`${getProductNew}/${branch_id}`),
  getProductPriceOffer: (branch_id) =>
    axiosInstance().get(`${getProductPriceOffer}/${branch_id}`),
};

export default menuServices;
