import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BottomSheet from "../ButtomSheet/BottomSheet";
import Visibility from "../utils/Visibility";
import BranchInfo from "./BranchInfo";
import SocialMedia from "./SocialMedia";

const Details = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  return (
    <BottomSheet
      toggleOpen={(value) => setOpen(value)}
      title={t("gloabl.details")}
      open={open}
      button={
        <div
          className="cursor-pointer p-1 w-full rounded-lg text-center bg-background text-onBackground"
          onClick={showDrawer}
        >
          {t("gloabl.details")}
        </div>
      }
    >
      <div className="flex flex-col">
        <BranchInfo branch={props.branch} />
        <Visibility visible={props.social && props.social.length !== 0}>
          <SocialMedia social={props.social} />
        </Visibility>
      </div>
    </BottomSheet>
  );
};
export default Details;
