import {
  BiLogoFacebook,
  BiLogoInstagramAlt,
  BiLogoWhatsapp,
  BiLogoTelegram,
  BiLogoSnapchat,
  BiLogoTwitter,
  BiMap,
} from "react-icons/bi";

export const socialMediaTypes = {
  0: <BiLogoFacebook className="text-lg" />,
  1: <BiLogoInstagramAlt className="text-lg" />,
  2: <BiLogoWhatsapp className="text-lg" />,
  3: <BiLogoTelegram className="text-lg" />,
  4: <BiLogoSnapchat className="text-lg" />,
  5: <BiLogoTwitter className="text-lg" />,
  6: <BiMap className="text-lg" />,
};
